import { defineMessages } from 'react-intl';

export const media = defineMessages({
    title: {
        id: 'media.title',
        defaultMessage: 'Images & Videos',
    },
    images: {
        id: 'media.images',
        defaultMessage: 'Images',
    },
    videos: {
        id: 'media.videos',
        defaultMessage: 'Videos',
    },
});
