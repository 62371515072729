import { toUploadcareImageDetailsShape } from '@/utils/toUploadcareImageShape';

export const FALLBACK_IMAGE = toUploadcareImageDetailsShape({
    size: 5924,
    total: 5924,
    done: 5924,
    uuid: '64c83544-7a5a-4680-9c84-0d2124fbb54b',
    file_id: '64c83544-7a5a-4680-9c84-0d2124fbb54b',
    original_filename: 'story-fallback.png',
    is_image: true,
    is_stored: true,
    image_info: {
        dpi: null,
        width: 1620,
        format: 'PNG',
        height: 1020,
        sequence: false,
        color_mode: 'P',
        orientation: null,
        geo_location: null,
        datetime_original: null,
    },
    video_info: null,
    content_info: {
        mime: { mime: 'image/png', type: 'image', subtype: 'png' },
        image: {
            dpi: null,
            width: 1620,
            format: 'PNG',
            height: 1020,
            sequence: false,
            color_mode: 'P',
            orientation: null,
            geo_location: null,
            datetime_original: null,
        },
    },
    is_ready: true,
    filename: 'storyfallback.png',
    mime_type: 'image/png',
    metadata: {},
});
