export interface HeaderData {
    productSections: ProductSection[];
    cartLink?: string;
    cartLabel?: string;
}

export type SectionWithLink = {
    title: string;
    link: string;
    isExternal: boolean;
    items?: never;
};

export type SectionWithSubSection = {
    title: string;
    items: SubSection[];
    link?: never;
    isExternal?: never;
};

export type ProductSection = SectionWithLink | SectionWithSubSection;

export type SectionWithProducts = {
    title: string;
    items: Product[];
};

export type SubSection = SectionWithLink | SectionWithProducts;

export interface Product {
    title: string;
    link: string;
    imageUrl: string;
}

export function hasSubSections(section: ProductSection): section is SectionWithSubSection {
    return 'items' in section;
}

export function hasProducts(section: SubSection): section is SectionWithProducts {
    return 'items' in section;
}
