import type { ImageProps } from '@prezly/uploadcare-image';

export type CardSize = 'tiny' | 'big' | 'extra-big';

export function getCardImageSizes(desiredSize: CardSize): ImageProps['sizes'] {
    // `tiny` is used for search thumbnail images
    if (desiredSize === 'tiny') {
        return {
            default: 90,
        };
    }

    // `extra-big` is used for highlighted story
    if (desiredSize === 'extra-big') {
        return {
            mobile: 420,
            tablet: 800,
            default: 1200,
        };
    }

    // `big` is used for normal story cards
    return {
        mobile: 420,
        tablet: 350,
        default: 600,
    };
}

export function getStoryImageSizes(): ImageProps['sizes'] {
    return {
        mobile: 414,
        tablet: 768,
        desktop: 992,
        default: 1920,
    };
}
