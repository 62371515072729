import * as headings from './headings';
import * as media from './media';
import * as misc from './misc';
import * as navigation from './navigation';
import * as sony from './sony';
import * as story from './story';

export const CUSTOM_MESSAGES = {
    ...headings,
    ...media,
    ...misc,
    ...navigation,
    ...sony,
    ...story,
};
