import { LocaleObject } from '@prezly/theme-kit-core';

import defaultFooterData from '@/constants/footer/en-UK.json';

export async function importFooterData(localeCode: string) {
    // There is no proper Global EU site, so we fall back to en-UK data.
    if (localeCode === 'en') {
        return defaultFooterData;
    }

    const locale = LocaleObject.fromAnyCode(localeCode);
    const localeIsoCode = locale.toHyphenCode();
    try {
        const data = await import(`constants/footer/${localeIsoCode}.json`);
        return data.default;
    } catch (error) {
        return defaultFooterData;
    }
}
