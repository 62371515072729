import type { UploadcareImageDetails } from '@prezly/uploadcare-image';

import { toUploadcareImageDetailsShape } from '@/utils/toUploadcareImageShape';

/**
 * The order of the images is important in this array:
 * 1. Corporate
 * 2. Digital Imaging
 * 3. Mobile
 * 4. Sound
 * 5. TV
 * 6. B2B (prod only)
 */
export const CATEGORY_IMAGES: UploadcareImageDetails[] = [
    {
        size: 1311117,
        total: 1311117,
        done: 1311117,
        uuid: 'c86eac08-613a-496b-9b6b-d3c26714a9e8',
        file_id: 'c86eac08-613a-496b-9b6b-d3c26714a9e8',
        original_filename: 'corporate.jpg',
        is_image: true,
        is_stored: true,
        image_info: {
            dpi: null,
            width: 1208,
            format: 'JPEG',
            height: 1707,
            sequence: false,
            color_mode: 'RGB',
            orientation: null,
            geo_location: null,
            datetime_original: null,
        },
        video_info: null,
        content_info: {
            mime: { mime: 'image/jpeg', type: 'image', subtype: 'jpeg' },
            image: {
                dpi: null,
                width: 1208,
                format: 'JPEG',
                height: 1707,
                sequence: false,
                color_mode: 'RGB',
                orientation: null,
                geo_location: null,
                datetime_original: null,
            },
        },
        is_ready: true,
        filename: 'corporate.jpg',
        mime_type: 'image/jpeg',
        metadata: {},
    },
    {
        size: 6734374,
        total: 6734374,
        done: 6734374,
        uuid: '75622a15-852f-437e-b0b7-f94c0264c3d3',
        file_id: '75622a15-852f-437e-b0b7-f94c0264c3d3',
        original_filename: 'digital-imaging.jpg',
        is_image: true,
        is_stored: true,
        image_info: {
            dpi: [240, 240],
            width: 4000,
            format: 'JPEG',
            height: 2667,
            sequence: false,
            color_mode: 'RGB',
            orientation: null,
            geo_location: null,
            datetime_original: '2022-09-25T07:56:25',
        },
        video_info: null,
        content_info: {
            mime: { mime: 'image/jpeg', type: 'image', subtype: 'jpeg' },
            image: {
                dpi: [240, 240],
                width: 4000,
                format: 'JPEG',
                height: 2667,
                sequence: false,
                color_mode: 'RGB',
                orientation: null,
                geo_location: null,
                datetime_original: '2022-09-25T07:56:25',
            },
        },
        is_ready: true,
        filename: 'digitalimaging.jpg',
        mime_type: 'image/jpeg',
        metadata: {},
    },
    {
        size: 7193581,
        total: 7193581,
        done: 7193581,
        uuid: '795a746a-998c-4e31-a627-f5ae98bc4176',
        file_id: '795a746a-998c-4e31-a627-f5ae98bc4176',
        original_filename: 'mobile.jpg',
        is_image: true,
        is_stored: true,
        image_info: {
            dpi: null,
            width: 3500,
            format: 'JPEG',
            height: 3500,
            sequence: false,
            color_mode: 'RGB',
            orientation: null,
            geo_location: null,
            datetime_original: null,
        },
        video_info: null,
        content_info: {
            mime: { mime: 'image/jpeg', type: 'image', subtype: 'jpeg' },
            image: {
                dpi: null,
                width: 3500,
                format: 'JPEG',
                height: 3500,
                sequence: false,
                color_mode: 'RGB',
                orientation: null,
                geo_location: null,
                datetime_original: null,
            },
        },
        is_ready: true,
        filename: 'mobile.jpg',
        mime_type: 'image/jpeg',
        metadata: {},
    },
    {
        size: 1168374,
        total: 1168374,
        done: 1168374,
        uuid: '48da0619-81ae-4131-b4b7-408fdfda14c8',
        file_id: '48da0619-81ae-4131-b4b7-408fdfda14c8',
        original_filename: 'sound.jpg',
        is_image: true,
        is_stored: true,
        image_info: {
            dpi: [144, 144],
            width: 2708,
            format: 'JPEG',
            height: 1674,
            sequence: false,
            color_mode: 'RGB',
            orientation: 1,
            geo_location: null,
            datetime_original: null,
        },
        video_info: null,
        content_info: {
            mime: { mime: 'image/jpeg', type: 'image', subtype: 'jpeg' },
            image: {
                dpi: [144, 144],
                width: 2708,
                format: 'JPEG',
                height: 1674,
                sequence: false,
                color_mode: 'RGB',
                orientation: 1,
                geo_location: null,
                datetime_original: null,
            },
        },
        is_ready: true,
        filename: 'sound.jpg',
        mime_type: 'image/jpeg',
        metadata: {},
    },
    {
        size: 3983667,
        total: 3983667,
        done: 3983667,
        uuid: '9c009775-0f6d-495a-83d1-4eb9882eb7fa',
        file_id: '9c009775-0f6d-495a-83d1-4eb9882eb7fa',
        original_filename: 'tv.jpg',
        is_image: true,
        is_stored: true,
        image_info: {
            dpi: [150, 150],
            width: 2571,
            format: 'JPEG',
            height: 1714,
            sequence: false,
            color_mode: 'RGB',
            orientation: 1,
            geo_location: null,
            datetime_original: null,
        },
        video_info: null,
        content_info: {
            mime: { mime: 'image/jpeg', type: 'image', subtype: 'jpeg' },
            image: {
                dpi: [150, 150],
                width: 2571,
                format: 'JPEG',
                height: 1714,
                sequence: false,
                color_mode: 'RGB',
                orientation: 1,
                geo_location: null,
                datetime_original: null,
            },
        },
        is_ready: true,
        filename: 'tv.jpg',
        mime_type: 'image/jpeg',
        metadata: {},
    },
    {
        size: 1200000,
        total: 1200000,
        done: 1200000,
        uuid: 'e710e3bc-894f-4354-9ac5-062ed9fdee50',
        file_id: 'e710e3bc-894f-4354-9ac5-062ed9fdee50',
        original_filename: 'PR Prezly B2B Card.png',
        is_image: true,
        is_stored: true,
        image_info: {
            dpi: [72, 72],
            width: 1600,
            format: 'PNG',
            height: 2701,
            sequence: false,
            color_mode: 'RGBA',
            orientation: null,
            geo_location: null,
            datetime_original: null,
        },
        video_info: null,
        content_info: {
            mime: {
                mime: 'image/png',
                type: 'image',
                subtype: 'png',
            },
            image: {
                dpi: [72, 72],
                width: 1600,
                format: 'PNG',
                height: 2701,
                sequence: false,
                color_mode: 'RGBA',
                orientation: null,
                geo_location: null,
                datetime_original: null,
            },
        },
        is_ready: true,
        filename: 'PRPrezlyB2BCard.png',
        mime_type: 'image/png',
        metadata: {},
    },
].map(toUploadcareImageDetailsShape);
