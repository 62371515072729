import { defineMessages } from 'react-intl';

export const headings = defineMessages({
    stories: {
        id: 'stories.title',
        defaultMessage: 'News',
    },
    homeStories: {
        id: 'homeStories.title',
        defaultMessage: 'Latest News',
    },
});
