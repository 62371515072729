export interface FooterData {
    mainSections?: MainSection[];
    secondaryContent?: SecondaryContent;
    metaContent?: MetaContent;
}

export type MainSectionWithSearch = {
    title: string;
    form: {
        action: string;
        label: string;
        submitText: string;
    };
    links: SectionLink[];
};

export type MainSectionWithLinks = {
    title: string;
    form?: never;
    links: SectionLink[];
};

export type MainSection = MainSectionWithSearch | MainSectionWithLinks;

export interface SectionLink {
    title: string;
    link: string;
}

export interface SocialLink {
    title: string;
    link: string;
    networkName: string;
    iconUrl: string;
}

export interface SecondaryContent {
    regionSelector: {
        currentLocale: string;
        link: string;
    };
    links: SectionLink[];
    socialLinks: SocialLink[];
}

export interface MetaContent {
    links: SectionLink[];
    copyright: string;
    sonyGroupLink: {
        title: string;
        link: string;
        imageUrl: string;
        hoverImageUrl: string;
    };
}

export function isMainSectionWithSearch(section: MainSection): section is MainSectionWithSearch {
    return 'form' in section;
}
