import type { PageProps } from '@prezly/theme-kit-nextjs';
import type { GetServerSidePropsContext } from 'next';

import type { BasePageProps } from 'types';

import { importFooterData } from './footerData';
import { importHeaderData } from './headerData';
import { isTrackingEnabled } from './isTrackingEnabled';
import { importMessages } from './lang';

export async function getCustomPageProps(
    context: GetServerSidePropsContext,
    { newsroomContextProps }: PageProps,
): Promise<BasePageProps> {
    return {
        isTrackingEnabled: isTrackingEnabled(context),
        translations: await importMessages(newsroomContextProps.localeCode),
        headerData: await importHeaderData(newsroomContextProps.localeCode),
        footerData: await importFooterData(newsroomContextProps.localeCode),
    };
}
