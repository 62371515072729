/* eslint-disable @typescript-eslint/naming-convention */
/**
 * If a locale is not listed here, it means it should fallback to the UK site
 */
export const SITE_URL_BY_LOCALE: Record<string, string> = {
    'de-AT': 'https://www.sony.at',
    'nl-BE': 'https://www.sony.be/nl',
    'fr-BE': 'https://www.sony.be/fr',
    'bg-BG': 'https://www.sony.bg',
    'cs-CZ': 'https://www.sony.cz',
    'da-DK': 'https://www.sony.dk',
    'fi-FI': 'https://www.sony.fi',
    'fr-FR': 'https://www.sony.fr',
    'de-DE': 'https://www.sony.de',
    'el-GR': 'https://www.sony.gr',
    'et-ET': 'https://www.sony.ee',
    'hu-HU': 'https://www.sony.hu',
    'hr-HR': 'https://www.sony.hr',
    'it-IT': 'https://www.sony.it',
    'lt-LT': 'https://www.sony.lt',
    'lv-LV': 'https://www.sonylatvija.com',
    'nl-NL': 'https://www.sony.nl',
    'nb-NO': 'https://www.sony.no', // See https://github.com/prezly/theme-nextjs-sony/pull/82#discussion_r1269408906
    'pl-PL': 'https://www.sony.pl',
    'pt-PT': 'https://www.sony.pt',
    'ro-RO': 'https://www.sony.ro',
    'sr-RS': 'https://www.sony.rs',
    'sl-SI': 'https://www.sony.si',
    'es-ES': 'https://www.sony.es',
    'sv-SE': 'https://www.sony.se',
    'fr-CH': 'https://www.sony.ch/fr',
    'de-CH': 'https://www.sony.ch/de',
    'tr-TR': 'https://www.sony.com.tr',
    'en-UK': 'https://www.sony.co.uk',
};
