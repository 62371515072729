import { defineMessages } from 'react-intl';

export const navigation = defineMessages({
    title: {
        id: 'navigation.title',
        defaultMessage: 'Press Centre',
    },
    news: {
        id: 'navigation.news',
        defaultMessage: 'News',
    },
    contacts: {
        id: 'navigation.contacts',
        defaultMessage: 'Contacts',
    },
    search: {
        id: 'navigation.search',
        defaultMessage: 'Search Press Centre',
    },
});
