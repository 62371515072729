import type { LocaleObject } from '@prezly/theme-kit-core';

import { SITE_URL_BY_LOCALE } from '@/constants';

export function getAbsoluteSonyUrl(url: string, locale: LocaleObject) {
    if (url.startsWith('http')) {
        return url;
    }

    const baseSiteUrl = SITE_URL_BY_LOCALE[locale.toHyphenCode()] ?? SITE_URL_BY_LOCALE['en-UK'];
    return `${baseSiteUrl}${url}`;
}
