interface UploadcareFullImageShape {
    size: number;
    total: number;
    done: number;
    uuid: string;
    file_id: string;
    original_filename: string;
    is_image: boolean;
    is_stored: boolean;
    image_info: ImageInfo;
    video_info: null;
    content_info: ImageContent;
    is_ready: boolean;
    filename: string;
    mime_type: string;
    metadata: {};
}
interface ImageInfo {
    dpi: number[] | null;
    width: number;
    format: string;
    height: number;
    sequence: boolean;
    color_mode: string;
    orientation: number | null;
    geo_location: null;
    datetime_original: string | null;
}
interface ImageContent {
    mime: ImageMime;
    image: ImageInfo;
}
interface ImageMime {
    mime: string;
    type: string;
    subtype: string;
}

export function toUploadcareImageDetailsShape(image: UploadcareFullImageShape) {
    return {
        version: 1,
        uuid: image.uuid,
        size: image.size,
        original_height: image.image_info.height,
        original_width: image.image_info.width,
        mime_type: image.mime_type,
        filename: image.filename,
        effects: [],
    };
}
