import { defineMessages } from 'react-intl';

export const sony = defineMessages({
    mySony: {
        id: 'sony.mySony',
        defaultMessage: 'My Sony',
    },
    myFavorites: {
        id: 'sony.myFavorites',
        defaultMessage: 'My Favorites',
    },
});
