import { getSupportedLocaleIsoCode, LocaleObject } from '@prezly/theme-kit-core';

import { loadCustomTranslations } from '@/intl';

export async function importMessages(localeCode: string) {
    const locale = LocaleObject.fromAnyCode(localeCode);
    const localeIsoCode = getSupportedLocaleIsoCode(locale);
    const customMessages = await loadCustomTranslations(localeIsoCode);
    const messages = await import(`@prezly/theme-kit-intl/messages/${localeIsoCode}.json`);
    return { ...messages.default, ...customMessages.default };
}
